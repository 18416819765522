import React from 'react';
import Home from '../pages/Home/Home';
import RouteConfig from './interface';
import renderRoutes from './utils';
import EmptyPageLayout from '../Layouts/EmptyPageLayout';
import LoginPage from '../pages/LoginPage/LoginPage';
import LogoutPage from '../pages/LogoutPage/LogoutPage';
import Profile from '../features/Profile/Profile';
import ResumeBuilder from '../features/ResumeBuilder/ResumeBuilder';

const routes: RouteConfig[] = [
	{
		title: 'Home',
		path: '/',
		component: <Home />,
		exact: true,
		isPublic: true,
	},
	{
		title: 'Login',
		path: '/login',
		component: <LoginPage />,
		layout: EmptyPageLayout,
		exact: true,
		isPublic: true,
	},
	{
		title: 'Logout',
		path: '/logout',
		component: <LogoutPage />,
		exact: true,
		isPublic: true,
	},
	{
		title: 'Profile',
		path: '/me',
		component: <Profile />,
		exact: true,
		isPublic: false,
	},
	{
		title: 'Resume Builder',
		path: '/resume-builder',
		component: <ResumeBuilder />,
		exact: false,
		isPublic: false,
	},
];

export const Routes = renderRoutes(routes);

export default routes;
