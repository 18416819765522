import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, CardHeader, CardMedia, Typography } from '@mui/material';
import { ReactFCC } from '../../interface/react';
import useAuth from '../../features/auth/useAuth';

interface ProfileBoxProps {
	onIconClick?: () => void;
}

const ProfileBox: ReactFCC<ProfileBoxProps> = ({ onIconClick }) => {
	const { user } = useAuth();
	const navigate = useNavigate();

	const displayName = user?.displayName || user?.email || 'User';
	const profileImage = user?.photoURL || 'https://mui.com/static/images/avatar/2.jpg';

	const onMenuItemClickHandler = (path: string) => {
		navigate(path);
		onIconClick?.();
	};

	return (
		<CardHeader
			avatar={
				<Avatar
					alt={displayName}
					sx={{ backgroundColor: '#fff', width: 48, height: 48, cursor: 'pointer' }}
					onClick={() => onMenuItemClickHandler('/me')}
				>
					<CardMedia component="img" image={profileImage} alt={displayName} loading="lazy" />
				</Avatar>
			}
			title={<Typography variant="subtitle2">{displayName}</Typography>}
			subheader={<Typography variant="caption">{user?.email}</Typography>}
		/>
	);
};

export default ProfileBox;
