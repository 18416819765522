import React from 'react';
import { ReactFCC } from '../../interface/react';

interface ResumeBuilderProps {}

const ResumeBuilder: ReactFCC<ResumeBuilderProps> = () => {
	return <div data-testid="resume-builder-component">Resume Builder</div>;
};

export default ResumeBuilder;
