import React from 'react';
import { ReactFCC } from '../../interface/react';
import styles from './Logo.module.scss';

interface LogoProps {}

const Logo: ReactFCC<LogoProps> = () => {
	return (
		<div className={styles.logo} data-testid="logo-component">
			Lesume
		</div>
	);
};

export default Logo;
