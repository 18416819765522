import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Button, Container, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { ReactFCC } from '../../interface/react';
import { pages } from '../../shared/menu';
import useAuth from '../../features/auth/useAuth';
import Logo from '../Logo/Logo';
import ProfileDropdownMenu from '../ProfileDropdownMenu/ProfileDropdownMenu';
import DrawerContent from '../DrowerContent/DrawerContent';
import useGlobal from '../../features/Global/useGlobal';

interface HeaderProps {}

const Header: ReactFCC<HeaderProps> = () => {
	const { isAuthenticated, isLoadingAuth } = useAuth();
	const { toggleDrawer } = useGlobal();
	const navigate = useNavigate();

	const gotoPage = (path: string) => {
		navigate(path);
	};

	const handleDrawerOpen = () => {
		toggleDrawer('main');
	};

	return (
		<Container sx={{ p: { xs: 0, sm: 2 } }}>
			<AppBar
				position="sticky"
				color="primary"
				sx={{
					borderRadius: {
						xs: 0,
						sm: 2,
					},
				}}
			>
				<Toolbar>
					<Box sx={{ flexGrow: 1 }}>
						<Button sx={{ ml: -1, color: 'white', display: 'block' }} onClick={() => gotoPage('/')}>
							<Logo />
						</Button>
					</Box>

					{isLoadingAuth ? (
						<Typography variant="overline" color="inherit" noWrap>
							Loading...
						</Typography>
					) : (
						<>
							<Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
								{pages.map((page) => (
									<Button
										key={page.title}
										size="small"
										sx={{ my: 0, color: 'white', display: 'block', textTransform: 'capitalize' }}
										onClick={() => gotoPage(page.path)}
									>
										{page.title}
									</Button>
								))}
							</Box>
							<Box sx={{ flexGrow: 0 }}>
								{isAuthenticated ? (
									<>
										<ProfileDropdownMenu />
										<IconButton
											color="inherit"
											aria-label="open drawer"
											onClick={handleDrawerOpen}
											edge="start"
											sx={{ ml: 1 }}
										>
											<MenuIcon />
										</IconButton>
									</>
								) : (
									<Button
										size="small"
										variant="outlined"
										startIcon={<PersonIcon />}
										onClick={() => gotoPage('/login')}
										sx={{ ml: 2, color: 'white', borderColor: 'white' }}
										disabled={isLoadingAuth}
									>
										Login
									</Button>
								)}
							</Box>
						</>
					)}
				</Toolbar>
			</AppBar>
			<DrawerContent />
		</Container>
	);
};

export default Header;
