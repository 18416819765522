import React from 'react';
import { Avatar, Card, Typography } from '@mui/material';
import { ReactFCC } from '../../interface/react';
import useAuth from '../auth/useAuth';

interface ProfileProps {}

const Profile: ReactFCC<ProfileProps> = () => {
	const { user } = useAuth();
	const displayName = user?.displayName || user?.email || 'User';

	return (
		<div data-testid="profile-component">
			<Card
				sx={{
					maxWidth: 345,
					my: {
						xs: 1,
						md: 3,
					},
					mx: 'auto',
					p: 5,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: 1,
				}}
			>
				<Avatar
					alt={displayName}
					src={user?.photoURL || 'https://mui.com/static/images/avatar/2.jpg'}
					sx={{
						width: 100,
						height: 100,
						mb: 2,
					}}
				/>
				<Typography variant="h5" component="div">
					{displayName}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					{user?.email}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					{user?.metadata.lastSignInTime}
				</Typography>
			</Card>
		</div>
	);
};

export default Profile;
