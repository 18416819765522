import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import RecommendOutlinedIcon from '@mui/icons-material/RecommendOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';

interface Page {
	title: string;
	icon: any;
	path: string;
}

export const pages: Page[] = [
	{ title: 'Home', icon: HomeIcon, path: '/' },
	{
		title: 'Resume Builder',
		icon: LocalLibraryIcon,
		path: '/resume-builder',
	},
];

export const settings: Page[] = [
	{ title: 'Profile', icon: AccountCircleIcon, path: '/me' },
	{ title: 'Logout', icon: LogoutIcon, path: '/logout' },
];

export const resumeBuilder: Page[] = [
	{
		title: 'Personal Information',
		icon: PersonOutlinedIcon,
		path: '/personal-information',
	},
	{
		title: 'Education',
		icon: SchoolOutlinedIcon,
		path: '/education',
	},
	{
		title: 'Experience',
		icon: WorkOutlineIcon,
		path: '/experience',
	},
	{
		title: 'Skills',
		icon: PsychologyOutlinedIcon,
		path: '/skills',
	},
	{
		title: 'Projects',
		icon: EmojiObjectsOutlinedIcon,
		path: '/projects',
	},
	{
		title: 'Awards',
		icon: EmojiEventsOutlinedIcon,
		path: '/awards',
	},
	{
		title: 'Interests',
		icon: InterestsOutlinedIcon,
		path: '/interests',
	},
	{
		title: 'Languages',
		icon: TranslateOutlinedIcon,
		path: '/languages',
	},
	{
		title: 'References',
		icon: RecommendOutlinedIcon,
		path: '/references',
	},
	{
		title: 'Courses',
		icon: MenuBookOutlinedIcon,
		path: '/courses',
	},
	{
		title: 'Certifications',
		icon: ReceiptOutlinedIcon,
		path: '/certifications',
	},
	{
		title: 'Publications',
		icon: NewspaperOutlinedIcon,
		path: '/publications',
	},
	{
		title: 'Volunteer',
		icon: VolunteerActivismOutlinedIcon,
		path: '/volunteer',
	},
	{
		title: 'Additional Information',
		icon: InfoOutlinedIcon,
		path: '/additional-information',
	},
	{
		title: 'Social Links',
		icon: Diversity3OutlinedIcon,
		path: '/social-links',
	},
	{
		title: 'Custom Sections',
		icon: DashboardCustomizeOutlinedIcon,
		path: '/custom-sections',
	},
];

export default { pages, settings };
