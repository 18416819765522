import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	Collapse,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { ReactFCC } from '../../interface/react';
import { resumeBuilder, settings } from '../../shared/menu';
import useGlobal from '../../features/Global/useGlobal';
import ProfileBox from '../ProfileBox/ProfileBox';
import { RESUME_BUILDER_PATH } from '../../features/ResumeBuilder/constants';

interface DrawerContentProps {}

const drawerWidth = 340;

const DrawerContent: ReactFCC<DrawerContentProps> = () => {
	const { isDrawerOpen, closeDrawer } = useGlobal();
	const navigate = useNavigate();
	const [open, setOpen] = React.useState(true);

	const handleClick = () => {
		setOpen(!open);
	};
	const handleDrawerClose = () => {
		closeDrawer('main');
	};

	const onMenuItemClickHandler = (path: string) => {
		navigate(path);
		handleDrawerClose();
	};

	return (
		<div data-testid="drawer-content-component">
			<Drawer
				anchor="left"
				open={isDrawerOpen.main}
				onClose={handleDrawerClose}
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				PaperProps={{
					sx: {
						justifyContent: 'space-between',
					},
				}}
			>
				<Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							px: 1,
							py: 0,
						}}
					>
						<ProfileBox onIconClick={handleDrawerClose} />
						<IconButton onClick={handleDrawerClose}>
							<ChevronLeftIcon />
						</IconButton>
					</Box>
					<Divider />
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						overflow: 'auto',
					}}
				>
					<ListItemButton onClick={handleClick}>
						<ListItemIcon>
							<LocalLibraryIcon />
						</ListItemIcon>
						<ListItemText primary="Resume Builder" />
						<ListItemSecondaryAction>
							<IconButton disableRipple edge="end">
								{open ? <ExpandLess /> : <ExpandMore />}
							</IconButton>
						</ListItemSecondaryAction>
					</ListItemButton>
					<Collapse
						in={open}
						timeout="auto"
						unmountOnExit
						sx={{
							overflow: 'auto',
						}}
					>
						<List component="div" disablePadding>
							{resumeBuilder.map((resumeBuilderPage) => {
								const PageIcon = resumeBuilderPage.icon;
								return (
									<ListItemButton
										key={resumeBuilderPage.path}
										sx={{ pl: 4 }}
										onClick={() =>
											onMenuItemClickHandler(`${RESUME_BUILDER_PATH}/${resumeBuilderPage.path}`.replace(/\/\//g, '/'))
										}
									>
										<ListItemIcon>
											<PageIcon />
										</ListItemIcon>
										<ListItemText primary={resumeBuilderPage.title} />
									</ListItemButton>
								);
							})}
						</List>
					</Collapse>
					<Divider />
				</Box>
				<Box>
					<Divider />
					<List>
						{settings.map((setting) => {
							const SettingIcon = setting.icon;
							return (
								<ListItem key={setting.path} disablePadding onClick={() => onMenuItemClickHandler(setting.path)}>
									<ListItemButton>
										<ListItemIcon>
											<SettingIcon />
										</ListItemIcon>
										<ListItemText primary={setting.title} />
									</ListItemButton>
								</ListItem>
							);
						})}
					</List>
				</Box>
			</Drawer>
		</div>
	);
};

export default DrawerContent;
