import React, { Dispatch, SetStateAction, createContext, useCallback, useState } from 'react';
import { ReactFCC } from '../../interface/react';
import { ThemeType } from './interface';

interface GlobalProviderContext {
	theme: ThemeType;
	setTheme: Dispatch<SetStateAction<ThemeType>>;
	isDrawerOpen: { [key: string]: boolean };
	toggleDrawer: (key: string) => void;
	openDrawer: (key: string) => void;
	closeDrawer: (key: string) => void;
}

const defaultValues = {} as GlobalProviderContext;

export const GlobalContext = createContext<GlobalProviderContext>(defaultValues);

interface GlobalProviderProps {}

export const GlobalProvider: ReactFCC<GlobalProviderProps> = ({ children }) => {
	const [theme, setTheme] = useState<ThemeType>(ThemeType.LIGHT);
	const [isDrawerOpen, setIsDrawerOpen] = useState<{ [key: string]: boolean }>({});

	const toggleDrawer = useCallback(
		(key: string) => {
			setIsDrawerOpen((prevState) => ({
				...prevState,
				[key]: !isDrawerOpen[key],
			}));
		},
		[isDrawerOpen, setIsDrawerOpen]
	);

	const openDrawer = useCallback(
		(key: string) => {
			setIsDrawerOpen((prevState) => ({
				...prevState,
				[key]: true,
			}));
		},
		[setIsDrawerOpen]
	);

	const closeDrawer = useCallback(
		(key: string) => {
			setIsDrawerOpen((prevState) => ({
				...prevState,
				[key]: false,
			}));
		},
		[setIsDrawerOpen]
	);

	const contextValue = React.useMemo(
		() => ({
			theme,
			setTheme,
			isDrawerOpen,
			toggleDrawer,
			openDrawer,
			closeDrawer,
		}),
		[theme, setTheme, isDrawerOpen, toggleDrawer, openDrawer, closeDrawer]
	);

	return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};

export default GlobalProvider;
